import React from "react";
import AddNewService from "../components/addNewService";

const AddNewOrder = () => {
  //main order state which will give overview of the whole order and push into
  //orders table in db

  //an array with list of service objects

  return (
    <div className="container bg-light">
      <h1 className="text-center my-3 py-3">ADD NEW ORDER</h1>
      <div className="my-3 mx-3">
        <hr />
        <AddNewService />
      </div>
      <hr />
    </div>
  );
};

export default AddNewOrder;
