import { React, useState } from "react";
import { Link } from "react-router-dom";
import { Form, Button, Row, Col, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const PickupRequest = () => {
  const backendURI = process.env.REACT_APP_BK_URL;

  let navigate = useNavigate();

  const [data, setData] = useState({
    pickup_date: "",
    customer_name: JSON.parse(localStorage.getItem("username")),
    nos: null,
    success: false,
    address: JSON.parse(localStorage.getItem("address")),
    time_slot: null,
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    const info = JSON.stringify({
      customer_name: data.customer_name.toLowerCase(),
      pickup_date: data.pickup_date,
      nos: data.nos,
      address: data.address,
      time_slot: data.time_slot,
    });
    // console.log(info);
    fetch(`${backendURI}/api/pickup`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: info,
    })
      .then((res) => res.json())
      .then((json) => {
        // console.log(json);
        if (json[0].customer_name) {
          setData({ ...data, success: true });
        }
      });
  };

  return (
    <div>
      {data.success === true ? (
        <Container className="my-3 ">
          <h3 className="text-info text-decoration-underline">
            Your Pick Up Request has been sent successfully.
          </h3>
          <p>
            Our representative will come on the date of pickup. Make sure the
            order is ready to be picked.
          </p>
          <h5>Thank you.</h5>
          <br />
          <Link to="/userdash"> &larr; Go back to dashboard.</Link>
        </Container>
      ) : (
        <Container>
          <br />
          <h2 className="text-decoration-underline">PickUp Request</h2>
          <br />
          <Form>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Customer Name</Form.Label>
                  <Form.Control
                    disabled
                    value={data.customer_name}
                    placeholder={data.customer_name}
                    type="text"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Pickup Date</Form.Label>
                  <Form.Control
                    onChange={(e) =>
                      setData({ ...data, pickup_date: e.target.value })
                    }
                    value={data.pickup_date}
                    type="date"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>No Of Garments</Form.Label>
                  <Form.Control
                    onChange={(e) => {
                      if (e.target.value < 15) {
                        <Form.Control.Feedback tooltip>
                          Please select greater than 15.
                        </Form.Control.Feedback>;
                      } else {
                        setData({ ...data, nos: e.target.value });
                      }
                    }}
                    value={data.name}
                    type="number"
                    min="15"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Check
                  className="my-3"
                  type="radio"
                  name="group1"
                  id="time_slot_1"
                  label="pickup slot 10am to 12pm"
                  value={1}
                  onChange={(e) =>
                    setData({ ...data, time_slot: e.target.value })
                  }
                />
                <Form.Check
                  className="my-3"
                  type="radio"
                  name="group1"
                  id="time_slot_2"
                  label="pickup slot 7pm to 9pm"
                  value={2}
                  onChange={(e) =>
                    setData({ ...data, time_slot: e.target.value })
                  }
                />
              </Col>
              <Col>
                <Button
                  className="my-4 rounded-3"
                  variant="success"
                  onClick={handleSubmit}
                  type="submit"
                >
                  Place Request
                </Button>
              </Col>
            </Row>
            <br />
            <h4 className="text-warning">Note:</h4>
            <p className="">
              1. Please Select Number Of Garments as more than 15. As we don't
              provide pickup service for orders below 15 garments.
            </p>
            <p>
              2. Please pick a date 1 to 2 days ahead from today, for proper
              pickup of your order.
            </p>
            <p>
              3. Please be ready with your order on the day and time for pickup.
            </p>
          </Form>
          <Button className=" my-5" onClick={() => navigate("/userdash")}>
            &#8592; Go to dashboard.
          </Button>
        </Container>
      )}
    </div>
  );
};

export default PickupRequest;
