import { React, useState, useEffect } from "react";
import { Container, Button, Row, Col, Table, Form } from "react-bootstrap";
import Header from "../components/header";

function EmployeeDash() {
  const backendURI = process.env.REACT_APP_BK_URL;

  const [data, setData] = useState([]);
  // const [info , setInfo] = useState({
  //   pickup_success: false
  // })
  // let pickup_success = false;

  const empInfo = {
    id: JSON.parse(localStorage.getItem("id")),
    username: JSON.parse(localStorage.getItem("username")),
    shop: JSON.parse(localStorage.getItem("address")),
  };
  useEffect(() => {
    fetch(`${backendURI}/api/pickup/`)
      .then((response) => response.json())
      .then((json) => {
        setData(json);
      });
  }, [backendURI, empInfo.username]);

  const handleSubmit = (e, item) => {
    e.preventDefault();
    const customer_name = item.customer_name;
    const nos = item.nos;
    const id = item.id;
    fetch(`${backendURI}/api/pickup`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify({
        id,
        customer_name,
        nos,
        pickedup: true,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        // console.log(json);
        alert(
          `The order with customer_name ${customer_name} has been picked. Refresh to see the change.`
        );
      });
  };

  return (
    <div>
      <Header />
      <br />
      <Container className="bg-secondary text-white">
        <br />
        <h2 className="text-decoration-underline">
          Welcome to Employee Dashboard
        </h2>
        <br />
        <Row>
          <Col>
            <h4>UserName: {empInfo.username}</h4>
          </Col>
          <Col>
            <h4>Employee Id : {empInfo.id}</h4>
          </Col>
          <Col>
            <h4>Shop: {empInfo.shop}</h4>
          </Col>
        </Row>
        <br />
        <Table responsive variant="dark" size="sm" borderless hover>
          <thead>
            <tr className="text-center">
              <th>Customer Name</th>
              <th>Address</th>
              <th>Nos</th>
              <th>Pickup Date</th>
              <th>Pickup Slot</th>
              <th>Order Picked</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data !== undefined ? (
              data
                .slice(0)
                .reverse()
                .map((item) => (
                  <tr className="my-2 mx-2 text-center" key={item.id}>
                    <th>{item.customer_name}</th>
                    <th>{item.address}</th>
                    <th>{item.nos}</th>
                    <th>{item.pickup_date}</th>
                    <th>
                      {item.time_slot === 1 ? "10am to 12pm " : "7pm to 9pm"}
                    </th>
                    <th>
                      <Form.Check
                        readOnly
                        id="complete"
                        value="completed"
                        checked={item.pickedup}
                        type="checkbox"
                        size="lg"
                      />
                    </th>
                    <th>
                      {empInfo.username === "ganesh" ||
                      empInfo.username === "shankar" ? null : (
                        <Button
                          onClick={(e) => handleSubmit(e, item)}
                          variant="success"
                          type="submit"
                          size="sm"
                        >
                          Picked Up
                        </Button>
                      )}
                    </th>
                  </tr>
                ))
            ) : (
              <h3>There are no orders for you.</h3>
            )}
          </tbody>
        </Table>
      </Container>
    </div>
  );
}

export default EmployeeDash;
