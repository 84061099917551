import React, { useState, useEffect } from "react";
import { Table, Card, Button } from "react-bootstrap";
import { useLocation, Link, useNavigate } from "react-router-dom";

const Details = () => {
  const backendURI = process.env.REACT_APP_BK_URL;
  const [custData, setCustData] = useState([]);
  const [data, setData] = useState([]);

  const location = useLocation();
  let navigate = useNavigate();

  useEffect(() => {
    // console.log(location.state.id);
    fetch(`${backendURI}/api/users/findone/${location.state.name}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((res) => res.json())
      .then((json) => {
        setCustData(json);
        // console.log(json);
      });

    fetch(`${backendURI}/api/details/${location.state.id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        // Authorization: `Bearer ${localStorage.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        setData(json);
        // console.log(json);
        // console.log(data);
      });
  }, [backendURI, location.state.id, location.state.name]);

  const backToDash = (e) => {
    const username = JSON.parse(localStorage.getItem("username"));
    if (username === "ganesh" || username === "shankar") {
      navigate("/admindash");
    } else {
      navigate("/userdash");
    }
  };

  return (
    <div>
      <Table responsive striped bordered hover>
        <thead>
          <tr>
            <th>order Id</th>
            <th>Category</th>
            <th>Garment</th>
            <th>Service</th>
            <th>Nos</th>
            <th>Price per piece</th>
            <th>Sub-total</th>
            <th>Comment</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={data.indexOf(item)}>
              <th> {item.order_id} </th>
              <th>{item.category}</th>
              <th> {item.garment} </th>
              <th> {item.service} </th>
              <th> {item.nos} </th>
              <th> {item.price} </th>
              <th> {item.subtotal} </th>
              <th> {item.comment} </th>
            </tr>
          ))}
        </tbody>
      </Table>
      <br />
      {custData[0] === undefined ? (
        <h4>Loading....</h4>
      ) : (
        <>
          <Card className="mx-3 border-primary" style={{ width: "18rem" }}>
            <Card.Body>
              <Card.Title>Customer Info</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                Available Info:
              </Card.Subtitle>
              <Card.Text>
                <h4 className="text-decoration-underline my-2">
                  Name:{custData[0].name}
                </h4>
                <h4 className="my-2">Phone: {custData[0].phone}</h4>
                <p className="my-2">Email: {custData[0].email}</p>
                <p className="my-2">Address: {custData[0].address}</p>
              </Card.Text>
            </Card.Body>
          </Card>
          <Button className="mx-3 my-5" onClick={(e) => backToDash(e)}>
            &#8592; Go to dashboard.
          </Button>
        </>
      )}
    </div>
  );
};

export default Details;
