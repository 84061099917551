import React, { useState, useEffect } from "react";
import { Table, Button, Form, Tabs, Tab, Row, Col } from "react-bootstrap";
import Header from "./header";
import "../App.css";
import { useNavigate } from "react-router-dom";

function Orders() {
  let navigate = useNavigate();

  const backendURI = process.env.REACT_APP_BK_URL;
  const [data, setData] = useState([]);
  // const [custData, setCustData] = useState([]);
  const [conditions, setConditions] = useState({
    process: false,
    completed: false,
    paid: false,
    paid_change_class_change: "",
    username: JSON.parse(localStorage.getItem("username")),
    shopname: JSON.parse(localStorage.getItem("address")),
    id: JSON.parse(localStorage.getItem("id")),
    home_delivery: false,
    pk_stat: false,
    order_id: undefined,
  });

  useEffect(() => {
    fetch(
      `${backendURI}/api/ordersadmin/${conditions.id}/${conditions.home_delivery}`
    )
      .then((response) => response.json())
      .then((json) => {
        if (json.dbError !== undefined) {
          setData(json.dbError.name);
        } else {
          setData(json);
        }
      });
  }, [conditions.id, backendURI, conditions.home_delivery]);
  useEffect(() => {
    fetch(`${backendURI}/api/pickup/pkstat/${conditions.username}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((res) => res.json())
      .then((json) => {
        setConditions({ ...conditions, pk_stat: json[0].ans });
      });
  }, [conditions.username, backendURI]);

  const handleDelete = (id) => {
    fetch(`${backendURI}/api/orders/${id}`, {
      method: "DELETE",
    })
      .then((res) => res.json())
      .then((json) => {
        alert(
          `The order with order id ${id} has been deleted. Refresh to see the change.`
        );
      });
  };

  const thePutRequest = (id, info) => {
    fetch(`${backendURI}/api/orders/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify({
        completed: true,
        customer_phone: info[0].phone,
      }),
    })
      .then((res) => res.json())
      .then((js) => {
        // console.log(js);
        setConditions({ ...conditions, completed: js[0].completed });
      });
    alert("order is completed");
  };

  const handleChange = async (e, id, customer_name) => {
    if (e.target.value === "process" && e.target.checked === true) {
      fetch(`${backendURI}/api/orders/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify({ process: true }),
      })
        .then((res) => res.json())
        .then((json) => {
          setConditions({ ...conditions, process: json[0].process });
        });
      alert("Order Is Processing");
    } else if (e.target.value === "completed" && e.target.checked === true) {
      await setConditions({ ...conditions, order_id: id });
      const res = await fetch(
        `${backendURI}/api/users/findone/${customer_name}`
      );
      const customerinfo = await res.json();
      // console.log(customerinfo);
      thePutRequest(id, customerinfo);
    } else if (e.target.value === "paid" && e.target.checked === true) {
      // setConditions({ ...conditions, paid_change_class_change: "bg-success" });
      fetch(`${backendURI}/api/orders/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify({ paid: true }),
      })
        .then((res) => res.json())
        .then((json) => {
          setConditions({ ...conditions, paid: json[0].paid });
          alert("Order Is Paid for");
        });
    }
  };

  const handleHd = (e) => {
    e.preventDefault();
    let ans = e.target.checked;

    fetch(`${backendURI}/api/pickup/stat/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify({
        admin: conditions.username,
        ans: ans,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        setConditions({ ...conditions, pk_stat: json[0].ans });
        alert(`The delivery on: ${ans}. Refresh to see change. `);
      });
  };

  const custOrderFind = (e) => {
    if (
      e.target.value.trim() === "" ||
      e.target.value === null ||
      e.target.value === undefined
    ) {
      fetch(
        `${backendURI}/api/ordersadmin/${conditions.id}/${conditions.home_delivery}`
      )
        .then((response) => response.json())
        .then((json) => {
          if (json.dbError !== undefined) {
            setData([
              {
                msg: "No order added for this admin. add orders by clicking on Add New Order + button",
              },
            ]);
          } else {
            setData(json);
          }
        });
    } else {
      setTimeout(() => {
        fetch(`${backendURI}/api/users/findone/${e.target.value}`)
          .then((response) => response.json())
          .then((json) => {
            if (json.dbError === "db error" || json[0] === undefined) {
              setData([{ msg: "No customer found by this phone number/Name" }]);
            } else {
              fetch(`${backendURI}/api/orders/findbyname/${json[0].name}`)
                .then((response) => response.json())
                .then((json) => {
                  if (json.dataExists === "false") {
                    setData([
                      { msg: `No orders found by this phone number/ name` },
                    ]);
                  } else {
                    setData(json);
                  }
                });
            }
          });
      }, 2000);
    }
  };

  return (
    <div className="bg-light mt-3">
      <Header />
      <br />
      <h3 className="text-danger mx-4">Shop: {conditions.shopname}</h3>
      <Row>
        <Col>
          <h4 className="text-primary mx-4">Admin: {conditions.username} </h4>
        </Col>
        <Col>
          <span> </span>
        </Col>
        <Col>
          <Form.Check
            checked={conditions.pk_stat}
            value=""
            onChange={(e) => {
              handleHd(e);
            }}
            label="Home delivery on/off"
          />
        </Col>
      </Row>
      <br />
      <div className="my-3 d-flex flex-row justify-content-center">
        <Button
          onClick={() => navigate("/neworders")}
          className="col mx-4 my-2"
          variant="outline-info"
        >
          Add New Order +
        </Button>

        <Button
          onClick={() => navigate("/newcustomer")}
          mx-auto="true"
          className="col mx-4 my-2"
          variant="outline-info"
        >
          Add New Customer +
        </Button>
        <Button
          onClick={() => navigate("/employeedash")}
          mx-auto="true"
          className="col mx-4 my-2"
          variant="outline-info"
        >
          Pickup Orders
        </Button>
        <Button
          onClick={() => navigate("/tally")}
          className="col mx-4 my-2"
          variant="outline-info"
        >
          Tally
        </Button>
      </div>
      <hr />
      <br />
      <div className="my-3 mx-3">
        <Row>
          <Col>
            <Form.Group>
              <Form.Label className="text-dark fs-4 text-decoration-underline mx-5">
                Find orders by Customer Ph Number/ Customer name
              </Form.Label>
              <Form.Control
                type="text"
                variant="outline-info"
                onChange={(e) => {
                  custOrderFind(e);
                }}
              />
            </Form.Group>
          </Col>
          <Col>
            {data[0] === undefined ? (
              <h3>data undefined</h3>
            ) : data[0].msg ? (
              <h3 className="mt-4 text-danger">{data[0].msg} </h3>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </div>

      <Tabs
        onClick={(e) => {
          if (e.target.id.split("-").includes("no")) {
            setConditions({ ...conditions, home_delivery: false });
          } else {
            setConditions({ ...conditions, home_delivery: true });
          }
        }}
        defaultActiveKey="no"
      >
        <Tab eventKey="no" title="Counter Orders">
          <div>
            <Table responsive striped bordered hover>
              <thead>
                <tr className="text-center">
                  <th></th>
                  <th>OwnerId</th>

                  <th>OrderId</th>
                  <th>Customer Name</th>
                  <th>NOS</th>
                  <th>Date Of Order</th>
                  {/* <th>Date Of Delivery</th> */}
                  <th>Total</th>
                  <th>Advance</th>
                  <th>Balence</th>
                  <th>Processing</th>
                  <th>Completed</th>
                  <th>Paid</th>
                  <th>View Details</th>
                </tr>
              </thead>
              <tbody>
                {data[0] === undefined ? (
                  <tr>data undefined</tr>
                ) : data[0].msg ? (
                  <tr className="text-warning text-center">{data[0].msg}</tr>
                ) : (
                  data
                    .slice(0)
                    .reverse()
                    .map((item) => (
                      <tr
                        className={
                          (item.paid === true ? "bg-success " : "") +
                          "my-2 mx-2 text-center "
                        }
                        key={item.id}
                      >
                        <th>
                          <Button
                            variant="danger"
                            onClick={() => handleDelete(item.id)}
                          >
                            Del
                          </Button>
                        </th>
                        <th>{item.owner_id}</th>
                        <th>{item.id}</th>
                        <th>{item.customer_name}</th>
                        <th>{item.nos}</th>
                        <th>{item.date_of_order}</th>
                        {/* <th>{item.date_of_delivery}</th> */}
                        <th>{item.total}</th>
                        <th>{item.advance}</th>
                        <th>{item.balence}</th>
                        <th>
                          <Form.Check
                            id="processing"
                            value="process"
                            checked={item.process}
                            onChange={(e) => handleChange(e, item.id)}
                            type="checkbox"
                            size="lg"
                          />
                        </th>
                        <th>
                          <Form.Check
                            id="complete"
                            value="completed"
                            checked={item.completed}
                            onChange={(e) =>
                              handleChange(e, item.id, item.customer_name)
                            }
                            type="checkbox"
                            size="lg"
                          />
                        </th>
                        <th>
                          <Form.Check
                            id="paid"
                            value="paid"
                            checked={item.paid}
                            onChange={(e) => handleChange(e, item.id)}
                            type="checkbox"
                            size="lg"
                          />
                        </th>
                        <th>
                          <Button
                            onClick={() =>
                              navigate("/details", {
                                state: {
                                  id: item.id,
                                  name: item.customer_name,
                                },
                              })
                            }
                            mx-auto="true"
                            className="mx-2 my-2"
                            variant="warning"
                            size="lg"
                          >
                            &#9432;
                          </Button>
                        </th>
                      </tr>
                    ))
                )}
              </tbody>
            </Table>
          </div>
        </Tab>
        <Tab eventKey="yes" title="Home Delivery Orders">
          <div>
            <Table responsive striped bordered hover>
              <thead>
                <tr className="text-center">
                  <th></th>
                  <th>OwnerId</th>

                  <th>OrderId</th>
                  <th>Customer Name</th>
                  <th>NOS</th>
                  <th>Date Of Order</th>
                  {/* <th>Date Of Delivery</th> */}
                  <th>Total</th>
                  <th>Advance</th>
                  <th>Balence</th>
                  <th>Processing</th>
                  <th>Completed</th>
                  <th>Paid</th>
                  <th>View Details</th>
                </tr>
              </thead>
              <tbody>
                {data === "error" ? (
                  <h3 className="text-warning">
                    {" "}
                    There are no orders. Add some dorders by clicking on 'Add
                    New Order +'{" "}
                  </h3>
                ) : (
                  data
                    .slice(0)
                    .reverse()
                    .map((item) => (
                      <tr className="my-2 mx-2 text-center" key={item.id}>
                        <th>
                          <Button
                            variant="danger"
                            onClick={() => handleDelete(item.id)}
                          >
                            Del
                          </Button>
                        </th>
                        <th>{item.owner_id}</th>
                        <th>{item.id}</th>
                        <th>{item.customer_name}</th>
                        <th>{item.nos}</th>
                        <th>{item.date_of_order}</th>
                        {/* <th>{item.date_of_delivery}</th> */}
                        <th>{item.total}</th>
                        <th>{item.advance}</th>
                        <th>{item.balence}</th>

                        <th>
                          <Form.Check
                            id="processing"
                            value="process"
                            checked={item.process}
                            onChange={(e) => handleChange(e, item.id)}
                            type="checkbox"
                            size="lg"
                          />
                        </th>
                        <th>
                          <Form.Check
                            id="complete"
                            value="completed"
                            checked={item.completed}
                            onChange={(e) => handleChange(e, item.id)}
                            type="checkbox"
                            size="lg"
                          />
                        </th>
                        <th>
                          <Form.Check
                            id="paid"
                            value="paid"
                            checked={item.paid}
                            onChange={(e) => handleChange(e, item.id)}
                            type="checkbox"
                            size="lg"
                          />
                        </th>
                        <th>
                          <Button
                            onClick={() =>
                              navigate("/details", { state: { id: item.id } })
                            }
                            mx-auto="true"
                            className="mx-2 my-2"
                            variant="warning"
                            size="lg"
                          >
                            &#9432;
                          </Button>
                        </th>
                      </tr>
                    ))
                )}
              </tbody>
            </Table>
          </div>
        </Tab>
      </Tabs>
    </div>
  );
}

export default Orders;
