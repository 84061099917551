import React from "react";
import Orders from "../components/orders";

const adminDash = () => {
  return (
    <div>
      <Orders />
    </div>
  );
};

export default adminDash;
