import React, { useEffect, useState } from "react";

import Header from "../components/header";
import Footer from "../components/footer";

import { Button, Table, Row, Col, Container } from "react-bootstrap";

const Services = () => {
  const backendURI = process.env.REACT_APP_BK_URL;

  const [data, setData] = useState([]);
  const [cat, setCat] = useState("mens");

  useEffect(() => {
    fetch(`${backendURI}/api/${cat}`)
      .then((response) => response.json())
      .then((json) => {
        setData(json);
      });
  }, [cat, backendURI]);

  const onBtnClick = (e) => {
    fetch(`${backendURI}/api/${e.target.value}`)
      .then((response) => response.json())
      .then((json) => {
        console.log(e.target.value);
        setData(json);
        setCat(e.target.value);
      });
  };

  return (
    <div className="App bg-light mt-3 mx-3">
      <Header />
      <div className="my-3">
        <h2 className="my-4 py-3 text-danger text-decoration-underline">
          {" "}
          Services Offer @ Milansons Drycleaners.
        </h2>
        <Row className="my-3">
          <Col>
            <h4>Dry-Cleaning</h4>
          </Col>
          <Col>
            <h4>Dry-Ironing </h4>
          </Col>
        </Row>
        <Row className="my-3">
          <Col>
            <h4> Steam-Ironing</h4>
          </Col>
          <Col>
            <h4> Starch</h4>
          </Col>
        </Row>
        <Row className="my-3">
          <Col>
            <h4>Rollpress</h4>
          </Col>
          <Col>
            <h4>Dyeing</h4>
          </Col>
        </Row>
        <Row className="my-3">
          <Col>
            <h4>Alteration</h4>
          </Col>
          <Col>
            <h4>Embroidery</h4>
          </Col>
        </Row>
      </div>
      <hr />
      <h2 className="text-danger text-decoration-underline my-4 py-4">
        Check The Rates For Services
      </h2>
      <Row className="my-3">
        <Col></Col>
        <Col>
          <Button
            onClick={onBtnClick}
            value="mens"
            variant="outline-success"
            size="sm"
          >
            View Rates For Men
          </Button>
        </Col>
        <Col>
          <Button
            onClick={onBtnClick}
            value="women"
            variant="outline-success"
            size="sm"
          >
            View Rates For Women
          </Button>
        </Col>
        <Col>
          <Button
            onClick={onBtnClick}
            value="kids"
            variant="outline-success"
            size="sm"
          >
            View Rates For Kids
          </Button>
        </Col>
        <Col>
          <Button
            onClick={onBtnClick}
            value="home_decor"
            variant="outline-success"
            size="sm"
          >
            View Rates For Home Decor
          </Button>
        </Col>
        <Col></Col>
      </Row>
      <h3 className="my-3"> Rates For {cat} </h3>
      <Container>
        <Table responsive striped bordered hover>
          <thead>
            <tr className="text-center">
              <th></th>
              <th>Garment</th>
              <th>Normal Press</th>
              <th>Steam Press</th>
              <th>Drycleaning</th>
              <th>Petrol Drycleaning</th>
              <th>Alteration</th>
              <th>Embroidary</th>
              <th>Dyeing</th>
              <th>Raffu</th>
            </tr>
          </thead>
          <tbody>
            {data
              .slice(0)
              .reverse()
              .map((item) => (
                <tr className="my-2 mx-2 text-center" key={item.id}>
                  <th>{item.id}</th>
                  <th>{item.garment}</th>
                  <th>{item.press}</th>
                  <th>{item.steam}</th>
                  <th>{item.dryclean}</th>
                  <th>{item.petrol_dryclean}</th>
                  <th>{item.alteration}</th>
                  <th>{item.embroidary}</th>
                  <th>{item.dyeing}</th>
                  <th>{item.raffu}</th>
                </tr>
              ))}
          </tbody>
        </Table>
      </Container>
      <hr />
      <Footer />
    </div>
  );
};

export default Services;
