import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";

const Customers = () => {
  const backendURI = process.env.REACT_APP_BK_URL;
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(`${backendURI}/api/customers`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => setData(json));
  }, [backendURI]);

  return (
    <div>
      <Table responsive striped bordered hover>
        <thead>
          <tr>
            <th>Id</th>
            <th>Name</th>
            <th>email</th>
            <th>Ph No</th>
            <th>Address</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.id}>
              <th> {item.id} </th>
              <th>{item.name}</th>
              <th> {item.email} </th>
              <th> {item.phone} </th>
              <th> {item.address} </th>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default Customers;
