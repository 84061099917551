import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
// import axios from "axios";

const backendURI = process.env.REACT_APP_BK_URL;

const NewCustomer = () => {
  const [data, setData] = useState({
    name: "",
    password: "",
    email: "",
    phone: "",
    address: "",
    success: false,
    err: undefined,
  });
  const [validated, setValidated] = useState(false);

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else if (form.checkValidity() === true) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
      fetch(`${backendURI}/api/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify({
          name: data.name.toLowerCase(),
          email: data.email,
          phone: data.phone,
          address: data.address,
          password: data.password,
        }),
      })
        .then((res) => res.json())
        .then((json) => {
          console.log(json);
          if (json.dbError) {
            setData({ ...data, err: json });
          } else {
            setData({ ...data, success: true });
            // setData({ ...data, err: json });
          }
        });
    }
  };

  //floating labes are awesome add them pls

  return (
    <div className="container">
      {data.err === undefined ? (
        data.success === false ? (
          <>
            {" "}
            <h2 className="mt-3 text-center">Add New Customer</h2>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Group className="mt-3" controlId="1122">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  required
                  onChange={(e) => setData({ ...data, name: e.target.value })}
                  value={data.name}
                  type="text"
                  placeholder="Enter Customer Name"
                />
              </Form.Group>
              <Form.Group className="mt-3" controlId="1123">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  required
                  onChange={(e) => setData({ ...data, email: e.target.value })}
                  value={data.email}
                  type="email"
                  placeholder="Enter Customer Email"
                />
              </Form.Group>
              <Form.Group className="mt-3" controlId="1124">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  required
                  onChange={(e) => setData({ ...data, phone: e.target.value })}
                  value={data.phone}
                  type="tel"
                  placeholder="+91 98******87"
                />
                <Form.Text className="text-muted">
                  Customer Phone Number For all Correspondence.
                </Form.Text>
              </Form.Group>
              <Form.Group className="mt-3" controlId="1125">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  required
                  onChange={(e) =>
                    setData({ ...data, address: e.target.value })
                  }
                  value={data.address}
                  type="text"
                  placeholder="Enter Customer Address"
                />
                <Form.Text className="text-muted">
                  Enter detail address so as to use for delivery in future.
                </Form.Text>
              </Form.Group>
              <Form.Group>
                <Form.Label>Password</Form.Label>
                <Form.Control
                  required
                  placeholder="strong password"
                  onChange={(e) =>
                    setData({ ...data, password: e.target.value })
                  }
                  type="password"
                />
              </Form.Group>
              <Button
                // onClick={handleSubmit}
                className="mt-3"
                variant="primary"
                type="submit"
              >
                Submit
              </Button>
            </Form>
          </>
        ) : (
          <h3 color="success">New Customer added successfully.</h3>
        )
      ) : (
        <h3>Form not filled completed please fill the form again.</h3>
      )}
    </div>
  );
};

export default NewCustomer;
