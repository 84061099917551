import React from "react";
import App from "./App";
import NewCustomer from "./pages/NewCustomer";
import NewOrder from "./pages/NewOrder";
import Services from "./pages/Services";
import Gallary from "./pages/Gallary";
import Contact from "./pages/Contact";
import Customers from "./pages/Customers";
import UserDash from "./pages/UserDash";
import PickupRequest from "./pages/PickupRequest";
import EmployeeDash from "./pages/EmployeeDash";
import AdminDash from "./pages/AdminDash";
import Details from "./pages/Details";
import SignInForm from "./components/signInForm";
import Orders from "./components/orders";
import Signupform from "./components/signupform";
import Tally from "./pages/Tally";

import { BrowserRouter, Routes, Route } from "react-router-dom";

const NavigationRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" exact element={<App />}></Route>
        <Route path="/neworders" element={<NewOrder />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/newcustomer" element={<NewCustomer />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/gallary" element={<Gallary />} />
        <Route path="/customers" element={<Customers />} />
        <Route path="/signin" element={<SignInForm />} />
        <Route path="/signup" element={<Signupform />} />
        <Route path="/userdash" element={<UserDash />} />
        <Route path="/pickuprequest" element={<PickupRequest />} />
        <Route path="/admindash" element={<AdminDash />} />
        <Route path="/employeedash" element={<EmployeeDash />} />
        <Route path="/details" element={<Details />} />
        <Route path="/tally" element={<Tally />} />

        {/* <Route path="/services" element={<Services />} /> */}
      </Routes>
    </BrowserRouter>
  );
};

export default NavigationRoutes;
