import React from "react";

import Header from "../components/header";
import Footer from "../components/footer";

// import { Container } from "react-bootstrap";

const Contact = () => {
  return (
    <div className="App bg-light mt-3 mx-3">
      <Header />
      <section className="my-4 py-3">
        <h2 className="text-danger text-decoration-underline">
          Milansons DryCleaners Shridhar Nagar Branch
        </h2>
        <h3 className="text-primary">
          Administrator:
          <br />
          Ganesh Kanojia
        </h3>
        <h3 className="my-3 py-3">Address :</h3>
        <h4>
          Shridhar Nagar, Saikrupa Socity, Near Elpro International School
          Chinchwad Pune 411033
        </h4>
        <h4>WhtsApp No :+91 9511754131</h4>
        <h4 className="my-3 py-3">Timings</h4>
        <h4>
          Shridhar Nagar Branch - 9.00 am To 9.pm
          <br />
          Lunch break - 2.00pm To 5.00pm
          <br />
          Thursday closed
        </h4>
      </section>
      <hr />
      <section className="my-4 py-3">
        <h2 className="text-danger text-decoration-underline">
          Milansons DryCleaners Kakade Township Branch
        </h2>
        <h3 className="text-primary">
          Administrator:
          <br />
          Shankar Kanojia
        </h3>
        <h3 className="my-3 py-3">Address :</h3>
        <h4>
          Kakade Township, Keshav Nagar, P Building Near Shani Mandir, Chinchwad
          Pune 411033
        </h4>
        <h4>WhtsApp No :+91 7775833128</h4>
        <h4 className="my-3 py-3">Timings</h4>
        <h4>
          Kakade Township Branch - 9.00 am To 9.pm
          <br />
          Lunch break - 2.00pm To 5.00pm
          <br />
          Thursday closed
        </h4>
      </section>
      <hr />
      <Footer />
    </div>
  );
};

export default Contact;
