import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import jwtDecode from "jwt-decode";

import Header from "../components/header";
import Footer from "../components/footer";

import { Container, Card } from "react-bootstrap";

const SignInForm = () => {
  const backendURI = process.env.REACT_APP_BK_URL;

  const [data, setData] = useState({
    Username: "",
    password: "",
    phone: "",
    success: false,
    role: "",
    err: undefined,
  });

  const adminCheck = (json) => {
    const decoded = jwtDecode(json.accessToken);

    if (decoded.name === data.Username) {
      localStorage.setItem("username", JSON.stringify(data.Username));
      localStorage.setItem("id", JSON.stringify(decoded.id));
      localStorage.setItem("token", JSON.stringify(json.accessToken));
      localStorage.setItem("role", JSON.stringify(decoded.role));
      localStorage.setItem("address", JSON.stringify(decoded.address));
      // localStorage.setItem("phone", JSON.stringify(decoded.phone));

      if (decoded.role === "admin") {
        setData({ ...data, role: "admin", success: true, err: undefined });
      } else if (decoded.role === "ruser") {
        setData({ ...data, role: "ruser", success: true, err: undefined });
      } else {
        setData({ ...data, role: "employee", success: true, err: undefined });
      }
    } else {
      setData({ ...data, err: decoded.Err });
      console.log(data.err);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    fetch(`${backendURI}/api/signin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify({
        name: data.Username.toLowerCase(),
        password: data.password,
        phone: data.phone,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.Err) {
          console.log(json.Err);
          setData({ ...data, err: json.Err, success: false });
        } else {
          adminCheck(json);
        }
      });
  };

  return (
    <div className="App bg-light mt-3 mx-3">
      <Header />
      <section className="my-3 py-3">
        <Card
          style={{
            width: "50%",
            height: "75%",
            margin: "auto",
            padding: "1rem",
          }}
        >
          <h2>Login</h2>
          <h3> {data.role}</h3>
          {data.err && <h3 className="text-danger">{data.err}</h3>}
          {data.success === true ? (
            <>
              <h3>Logged In successfully. </h3>

              {data.role === "admin" && (
                <p>
                  go to <Link to="/admindash"> dashboard </Link>{" "}
                </p>
              )}
              {data.role === "ruser" && (
                <p>
                  go to <Link to="/userdash"> dashboard </Link>
                </p>
              )}
              {data.role === "employee" && (
                <p>
                  {" "}
                  got to <Link to="/employeedash">dashboard</Link>
                </p>
              )}
            </>
          ) : (
            <>
              <Form>
                <Form.Group>
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    onChange={(e) =>
                      setData({ ...data, Username: e.target.value })
                    }
                    type="text"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    onChange={(e) =>
                      setData({ ...data, password: e.target.value })
                    }
                    type="password"
                  />
                </Form.Group>
                <Button className="mt-2" onClick={handleSubmit} type="submit">
                  Submit
                </Button>
              </Form>
              <p className="mt-4">
                Not registered? <Link to="/signup">SignUp now..!</Link>
              </p>
            </>
          )}
        </Card>
      </section>

      <hr />
      <Footer />
    </div>
  );
};

export default SignInForm;
