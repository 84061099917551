import React, { useState } from "react";
import { Form, Button, Card } from "react-bootstrap";

import Header from "../components/header";
import Footer from "../components/footer";
// import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Signupform = () => {
  const backendURI = process.env.REACT_APP_BK_URL;
  let navigate = useNavigate();

  const [data, setData] = useState({
    name: "",
    password: "",
    phone: "",
    email: "",
    address: "",
    success: false,
    err: "",
  });
  const [returndData, setRD] = useState({
    id: null,
    name: "",
    email: "",
    phone: "",
    address: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch(`${backendURI}/api/signup`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify({
        name: data.name.toLowerCase(),
        phone: data.phone,
        email: data.email,
        address: data.address,
        password: data.password,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        if (json[0].name) {
          setData({ ...data, success: true });
          setRD({
            ...returndData,
            name: json[0].name,
            address: json[0].address,
            phone: json[0].phone,
            email: json[0].email,
          });
        }
      });
  };

  const setPhone = (v) => {
    console.log(v);
    if (v.includes("+91")) {
      setData({ ...data, phone: v });
    } else {
      setData({ ...data, phone: "+91".concat(v) });
    }
  };

  return (
    <div className=" App bg-light mt-3 mx-3">
      <Header />

      {data.success === false ? (
        <Card
          style={{
            width: "50%",
            height: "75%",
            margin: "auto",
            padding: "1rem",
          }}
        >
          <h2 className="text">Register</h2>
          <br />
          <Form>
            <Form.Group>
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                required
                placeholder="Kevin Heart"
                onChange={(e) => setData({ ...data, name: e.target.value })}
                type="text"
              />
            </Form.Group>
            <br />
            <Form.Group>
              <Form.Label>Phone</Form.Label>
              <Form.Control
                required
                placeholder="+9177******67"
                onChange={(e) => setPhone(e.target.value)}
                type="text"
              />
            </Form.Group>
            <br />

            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control
                required
                placeholder="kev@gmal.com"
                onChange={(e) => setData({ ...data, email: e.target.value })}
                type="text"
              />
            </Form.Group>
            <br />

            <Form.Group>
              <Form.Label>Address</Form.Label>
              <Form.Control
                required
                placeholder="complete address for home delivery"
                onChange={(e) => setData({ ...data, address: e.target.value })}
                type="text"
              />
            </Form.Group>
            <br />

            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control
                required
                placeholder="strong password"
                onChange={(e) => setData({ ...data, password: e.target.value })}
                type="password"
              />
            </Form.Group>
            <br />
            <Button onClick={handleSubmit} type="submit">
              Submit
            </Button>
          </Form>
        </Card>
      ) : (
        <Card>
          <Card.Body>
            <Card.Title>You are Registered with Milansons.</Card.Title>
            <Card.Text>
              With Following Details:
              <br />
              name: {returndData.name}
              <br />
              Phone:{returndData.phone}
              <br />
              Address: {returndData.address}
            </Card.Text>
            <Button
              onClick={() =>
                navigate("/userdash", { state: { phone: returndData.phone } })
              }
            >
              Go to dashboard.
            </Button>
          </Card.Body>
        </Card>
      )}
    </div>
  );
};

export default Signupform;
