import React from "react";

import logo from "../assets/images/image3.png";

import { Row, Col } from "react-bootstrap";

import { pdfjs } from "react-pdf";

import { IconContext } from "react-icons";
// import { ImInstagram } from "react-icons/im";
import { BsInstagram } from "react-icons/bs";
import { FaFacebook } from "react-icons/fa";
import { BsWhatsapp } from "react-icons/bs";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
const footer = () => {
  return (
    <div className="bg-light py-5 ">
      <Row className="py-5">
        <Col>
          <img src={logo} alt="" style={{ height: "15rem", widh: "15rem" }} />
        </Col>

        <Col className="">
          <h3 className="text-danger text-decoration-underline py-2">
            Important Links
          </h3>
          <ul className="list-group  mx-3">
            <li className="list-group-item bg-light py-2">
              <a
                className="text-decoration-none text-primary "
                // style={{ color: "yellow" }}
                href={require("../utils/Guaranteed Cloth Protection Program.pdf")}
                target="blank"
              >
                Guaranteed Cloth Protection Program
              </a>
            </li>
            <li className="list-group-item bg-light py-2">
              <a
                className="text-decoration-none text-primary"
                href={require("../utils/Privacy Policy.pdf")}
                target="blank"
              >
                Privacy Policy
              </a>
            </li>
            <li className="list-group-item bg-light py-2">
              <a
                className="text-decoration-none text-primary"
                href={require("../utils/Termsandconditions.pdf")}
                target="blank"
              >
                Terms & conditions
              </a>
            </li>
          </ul>
          {/* <ListGroup>
            <ListGroup.Item
              href={"../"}
              target="blank"
            >
              Terms & Conditions
            </ListGroup.Item>
            <ListGroup.Item
              href={require("../utils/Privacy Policy.pdf")}
              target="blank"
            >
              Privacy Policy
            </ListGroup.Item>
            <ListGroup.Item
              href={require("../utils/Guaranteed Cloth Protection Program.pdf")}
              target="blank"
            >
              Guaranteed Cloth Protection Program
            </ListGroup.Item>
          </ListGroup> */}
        </Col>
        <Col>
          <h3 className="text-danger text-decoration-underline mb-5 mt-2">
            {" "}
            Follow Us On{" "}
          </h3>
          <Row>
            <Col>
              <IconContext.Provider value={{ color: "black", size: "3rem" }}>
                <div>
                  <a
                    href="https://instagram.com/milan_sons?igshid=YmMyMTA2M2Y="
                    target="blank"
                  >
                    <BsInstagram />
                  </a>
                </div>
              </IconContext.Provider>
            </Col>
            <Col>
              <IconContext.Provider value={{ color: "black", size: "3rem" }}>
                <div>
                  <a
                    href="https://www.facebook.com/milansonsdrycleaners?mibextid=LQQJ4d"
                    target="blank"
                  >
                    <FaFacebook />
                  </a>
                </div>
              </IconContext.Provider>
            </Col>
            <Col>
              <IconContext.Provider value={{ color: "black", size: "3rem" }}>
                <div>
                  <BsWhatsapp />
                  <br />
                  <h4 className="my-3">+919511754131</h4>
                </div>
              </IconContext.Provider>
            </Col>
          </Row>
        </Col>
      </Row>
      <p className="text-center">
        © Copyright 2023. All Rights Reserved.This software is desgined and
        developed by AUX-software.
      </p>
    </div>
  );
};

export default footer;
