import React from "react";
import Header from "../components/header";
import Footer from "../components/footer";

import { Row, Col, Container } from "react-bootstrap";

import Img1 from "../assets/images/image0.jpeg";
import Img2 from "../assets/images/image1(1).jpeg";
import Img3 from "../assets/images/image4.jpeg";
import Img4 from "../assets/images/image5.jpeg";
import Img5 from "../assets/images/image6.jpeg";
import Img6 from "../assets/images/image7.jpeg";
import Img7 from "../assets/images/image8.jpeg";
import Img8 from "../assets/images/image10.jpeg";
import Img10 from "../assets/images/image11.png";
import Img9 from "../assets/images/image9.png";
const Gallary = () => {
  return (
    <div className="App bg-light mt-3 mx-3">
      <Header />
      <h2 className="text-danger text-decoration-underline my-3 py-3">
        Our Services And Location
      </h2>
      <Container className="my-3 py-3">
        <Row>
          <Col>
            <img
              className=" mx-2 my-2 "
              src={Img1}
              style={{ height: "500px", width: "500px" }}
              alt="Img1"
            />
          </Col>
          <Col>
            {" "}
            <img
              className=" mx-2 my-2"
              src={Img2}
              style={{ height: "500px", width: "500px" }}
              alt="Img1"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            {" "}
            <img
              className="mx-2 my-2"
              src={Img3}
              style={{ height: "500px", width: "500px" }}
              alt="Img1"
            />
          </Col>
          <Col>
            {" "}
            <img
              className=" mx-2 my-2"
              src={Img4}
              style={{ height: "500px", width: "500px" }}
              alt="Img1"
            />
          </Col>
        </Row>{" "}
        <Row>
          <Col>
            {" "}
            <img
              className=" mx-2 my-2"
              src={Img5}
              style={{ height: "500px", width: "500px" }}
              alt="Img1"
            />
          </Col>
          <Col>
            {" "}
            <img
              className=" mx-2 my-2"
              src={Img6}
              style={{ height: "500px", width: "500px" }}
              alt="Img1"
            />
          </Col>
        </Row>{" "}
        <Row>
          <Col>
            {" "}
            <img
              className=" mx-2 my-2"
              src={Img7}
              style={{ height: "500px", width: "500px" }}
              alt="Img1"
            />
          </Col>
          <Col>
            {" "}
            <img
              className=" mx-2 my-2"
              src={Img8}
              style={{ height: "500px", width: "500px" }}
              alt="Img1"
            />
          </Col>
        </Row>{" "}
        <Row>
          <Col>
            {" "}
            <img
              className=" mx-2 my-2"
              src={Img9}
              style={{ height: "500px", width: "500px" }}
              alt="Img1"
            />
          </Col>
          <Col>
            {" "}
            <img
              className=" mx-2 my-2"
              src={Img10}
              style={{ height: "500px", width: "500px" }}
              alt="Img1"
            />
          </Col>
        </Row>
      </Container>
      <hr />
      <Footer />
    </div>
  );
};

export default Gallary;
