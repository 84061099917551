import React, { useState, useEffect } from "react";

import moment from "moment";
import { useNavigate } from "react-router-dom";

import {
  Table,
  Container,
  Form,
  Button,
  Row,
  Col,
  Link,
} from "react-bootstrap";

const Tally = () => {
  const backendURI = process.env.REACT_APP_BK_URL;

  let navigate = useNavigate();

  const [conditions, setConditions] = useState({
    process: false,
    completed: false,
    username: JSON.parse(localStorage.getItem("username")),
    shopname: JSON.parse(localStorage.getItem("address")),
    id: JSON.parse(localStorage.getItem("id")),
    home_delivery: false,
    pk_stat: false,
    err: undefined,
    tallyAmount: 0,
    totalPaid: 0,
    startDate: "",
    endDate: "",
  });
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(
      `${backendURI}/api/ordersadmin/${conditions.id}/${conditions.home_delivery}`
    )
      .then((response) => response.json())
      .then((json) => {
        if (conditions.startDate === "" || conditions.endDate === "") {
          setData(json);
        } else {
          let arr1 = json.map((p) => {
            if (
              moment(p.dates_of_orders.split("T")[0]).isBetween(
                conditions.startDate,
                conditions.endDate
              )
            ) {
              return p;
            } else {
              return { 0: 0 };
            }
          });
          setData(arr1);
        }
      });
  }, [
    backendURI,
    conditions.home_delivery,
    conditions.id,
    conditions.startDate,
    conditions.endDate,
  ]);

  useEffect(() => {
    // console.log(data);
    let arr1totalforallorders = data.map((i) => {
      if (i.total !== undefined) {
        return i.total;
      } else {
        return 0;
      }
    });

    let arr2totalpaid = data.map((i) => {
      if (i.paid === true) {
        return i.total;
      } else {
        return 0;
      }
    });

    // console.log("line 77:", arr1totalforallorders);
    // console.log(arr2totalpaid, "line78:");

    if (data.length === 0) {
      console.log("Error for total paid amount");
    } else {
      console.log("hello form else for num and sum");
      let num = 0;
      let sum = 0;
      for (let i = 0; i < arr1totalforallorders.length; i++) {
        sum += parseInt(arr1totalforallorders[i]);
      }

      for (let i = 0; i < arr2totalpaid.length; i++) {
        if (i !== 0 || i !== undefined) {
          num += parseInt(arr2totalpaid[i]);
        }
      }
      setConditions({ ...conditions, totalPaid: num, tallyAmount: sum });
    }
  }, [data]);

  return (
    <div>
      <Container>
        <h2 className="text-center text-warning text-decoration-underline">
          Tally
        </h2>
        <br />
        <p className="text-center">
          Please select the dates in boxes below to get the tally between the
          dates. By default tally of last 500 orders.
        </p>
        <br />
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Tally Start Date</Form.Label>
            <Form.Control
              type="date"
              placeholder="Enter start date"
              onChange={(e) => {
                setConditions({ ...conditions, startDate: e.target.value });
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Tally End Date</Form.Label>
            <Form.Control
              type="date"
              placeholder="Enter end date"
              onChange={(e) => {
                setConditions({ ...conditions, endDate: e.target.value });
              }}
            />
          </Form.Group>
        </Form>
        <br />
        <div className="mx-3 my-3 text-danger font-weight-bold">
          <Row>
            <Col className="text-center">
              Tally Start Date Selected :
              <span className="text-decoration-underline">
                {conditions.startDate}
              </span>
            </Col>
            <Col className="text-center">
              {" "}
              Tally End Date Selected :
              <span className="text-decoration-underline">
                {" "}
                {conditions.endDate}{" "}
              </span>{" "}
            </Col>
          </Row>
        </div>
        <br />
        {conditions.err !== undefined ? (
          <h3>{conditions.err}</h3>
        ) : (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th className="text-center">Admin Name</th>
                <th className="text-center">Shop Name</th>
                <th className="text-center">Total Tally for this period</th>
                <th className="text-center">
                  Total amount paid in this period
                </th>
                <th className="text-center">Total amount receivable </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-center">{conditions.username}</td>
                <td className="text-center">{conditions.shopname}</td>
                <td className="text-center">₹ {conditions.tallyAmount}/-</td>
                <td className="text-center">₹ {conditions.totalPaid}/-</td>
                <td className="text-center">
                  ₹ {conditions.tallyAmount - conditions.totalPaid}/-
                </td>
              </tr>
            </tbody>
          </Table>
        )}
      </Container>
      <br />
      <br />
      <Button className="mx-3 my-5" onClick={() => navigate("/admindash")}>
        &#8592; Go to dashboard.
      </Button>
    </div>
  );
};

export default Tally;
