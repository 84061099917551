import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import logo from "../assets/images/image3.png";

const ImportComponent = () => {
  let navigate = useNavigate();

  return (
    <div>
      <Navbar
        collapseOnSelect
        expand="sm"
        bg="warning"
        variant="dark"
        className="d-flex justify-content-center"
      >
        <Navbar.Brand className="mx-2 text-danger" href="/">
          <img
            src={logo}
            width="30"
            height="30"
            className="d-inline-block align-top mx-3"
            alt="MlDry logo"
          />
          Milansons
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className=" me-auto fs-5">
            <Nav.Link className="text-dark" onClick={() => navigate("/")}>
              Home
            </Nav.Link>
            <Nav.Link
              className="text-dark"
              onClick={() => navigate("/services")}
            >
              Services
            </Nav.Link>
            <Nav.Link
              className="text-dark"
              onClick={() => navigate("/gallary")}
            >
              Gallary
            </Nav.Link>
            <Nav.Link
              className="text-dark"
              onClick={() => navigate("/contact")}
            >
              Contact
            </Nav.Link>
          </Nav>
          <Nav className=" mx-5 fs-5">
            {localStorage.token === undefined ? (
              <Nav.Link
                className="text-dark"
                onClick={() => navigate("/signin")}
              >
                Login
              </Nav.Link>
            ) : (
              <Nav.Link
                className="text-dark"
                onClick={() => {
                  localStorage.removeItem("token");
                  navigate("/");
                }}
              >
                Logout
              </Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default ImportComponent;
