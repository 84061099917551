import React, { useState, useEffect } from "react";

import { Table, Container, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Header from "../components/header";
import Footer from "../components/footer";

const UserDash = () => {
  let navigate = useNavigate();
  const [conditions, setConditions] = useState({
    pk_stat: [],
  });
  const [data, setData] = useState([]);

  const backendURI = process.env.REACT_APP_BK_URL;
  const usrname = JSON.parse(localStorage.getItem("username"));

  useEffect(() => {
    fetch(`${backendURI}/api/orders/findbyname/${usrname}`)
      .then((response) => response.json())
      .then((json) => {
        if (json.dataExists === "false") {
          // console.log(json.dbError.name);
          // console.log("hey json.data.exists===false");
          setData("error");
        } else {
          setData(json);
        }
      });
  }, [backendURI, usrname, conditions]);

  useEffect(() => {
    fetch(`${backendURI}/api/pickupStat`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((res) => res.json())
      .then((json) => {
        // console.log(json);
        setConditions({ ...conditions, pk_stat: json });
        // console.log("thepickupstatis", conditions.pk_stat);
      });
  }, []);
  return (
    <div>
      <Header />
      <br />
      <h2 className=" text-danger text-decoration-underline text-center mx-2 my-2">
        Welcome to Milansons DryCleaners
      </h2>
      <br />
      <p className="text-center mx-2 my-2">
        You can check the status of your orders here. <br />
        Also, you can place a request for pickup.
      </p>
      <br />
      <Container>
        <div>
          <br />
          <div>
            <h3 className="text-center">Pickup Service Status</h3>
            <br />
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th className="text-center">Branch Name</th>
                  <th className="text-center">Admin Name</th>
                  <th className="text-center">
                    Pickup/Delivery Service status
                  </th>
                </tr>
              </thead>
              <tbody>
                {conditions.pk_stat.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td className="font-weight-normal text-center">
                        {item.admin === "ganesh"
                          ? " Shridhar Nagar Branch"
                          : "Kakde Township Branch"}
                      </td>
                      <td className="font-weight-normal text-center">
                        {item.admin.toUpperCase()}
                      </td>
                      <td className="font-weight-normal text-center">
                        {item.ans === true
                          ? " Pickup service available. Place a request NOW!"
                          : "Sorry, pickup Service NOT available at this branch right now."}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
          <div className="d-flex flex-row justify-content-center">
            {
              <Button
                onClick={() => navigate("/pickuprequest")}
                className="mx-3"
                variant="warning"
              >
                Place PickUp Request
              </Button>
            }

            {/* <Button className="mx-3" variant="danger">
              View PickUp Status
            </Button>

            <Button className="mx-3" border="danger">
              View Orders Status
            </Button> */}
          </div>
        </div>
        <br />
        <br />
        <br />

        <h2 className="text-primary mx-2 my-3">
          Drycleaning orders and staus for
          <span className="text-decoration-underline mx-2">{usrname}</span>
        </h2>
        <div>
          <Table responsive variant="dark" size="sm" borderless hover>
            <thead>
              <tr className="text-center">
                <th>OrderId</th>
                <th>Customer Name</th>
                <th>NOS</th>
                <th>Date Of Order</th>
                {/* <th>Date Of Delivery</th> */}
                <th>Total</th>
                <th>Advance</th>
                <th>Balence</th>
                <th>Processing</th>
                <th>Completed</th>
                <th>View Details</th>
              </tr>
            </thead>
            <tbody>
              {data === "error" ? (
                <th>
                  <p className="text-warning">
                    No orders added. Add pickup request for home delivery orders
                    or contact shop.
                  </p>
                </th>
              ) : (
                data
                  .slice(0)
                  .reverse()
                  .map((item) => (
                    <tr className="my-2 mx-2 text-center" key={item.id}>
                      <th>{item.id}</th>
                      <th>{item.customer_name}</th>
                      <th>{item.nos}</th>
                      <th>{item.date_of_order}</th>
                      {/* <th>{item.date_of_delivery}</th> */}
                      <th>{item.total}</th>
                      <th>{item.advance}</th>
                      <th>{item.balence}</th>
                      <th>
                        <Form.Check
                          readOnly
                          id="processing"
                          value="process"
                          checked={item.process}
                          type="checkbox"
                          size="lg"
                        />
                      </th>
                      <th>
                        <Form.Check
                          readOnly
                          id="complete"
                          value="completed"
                          checked={item.completed}
                          type="checkbox"
                          size="lg"
                        />
                      </th>
                      <th>
                        <Button
                          onClick={() =>
                            navigate("/details", {
                              state: {
                                id: item.id,
                                name: item.customer_name,
                              },
                            })
                          }
                          mx-auto="true"
                          className="mx-2 my-2 rounded-pill"
                          variant="info"
                          size="sm"
                        >
                          &#9432;
                        </Button>
                      </th>
                    </tr>
                  ))
              )}
            </tbody>
          </Table>
        </div>
      </Container>
      <br />
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
};

export default UserDash;
