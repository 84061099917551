import React from "react";
import "./App.css";
import Header from "./components/header";
import Footer from "./components/footer";

import { Row, Col, Accordion, Carousel } from "react-bootstrap";
import heroImg from "../src/assets/images/image2.jpeg";
import carouselImg1 from "../src/assets/images/image0.jpeg";
import carouselImg2 from "../src/assets/images/image1(1).jpeg";
import carouselImg3 from "../src/assets/images/image9.png";

import { IconContext } from "react-icons";
import { GiWallet } from "react-icons/gi";
import { TbTruckDelivery } from "react-icons/tb";
import { BsCheck2Square } from "react-icons/bs";
import { TiStopwatch } from "react-icons/ti";

function App() {
  return (
    <div className="App bg-light mt-3 mx-3">
      <Header />
      <div className="my-3">
        <img src={heroImg} className="img-fluid" alt="heroImage" />
      </div>
      <br />
      <hr />
      <div className="">
        <h2 className="text-danger text-decoration-underline my-5 py-3">
          Our Promise
        </h2>
        <Row className="my-5 py-3 ">
          <Col>
            <IconContext.Provider value={{ color: "black", size: "4rem" }}>
              <div>
                <GiWallet />
              </div>
            </IconContext.Provider>
            <br /> <h4 className="text-warning">Affordable</h4>
            <p>
              Our pricing policy is fair & transparent. What you see is what you
              pay!
            </p>
          </Col>
          <Col>
            <IconContext.Provider value={{ color: "black", size: "4rem" }}>
              <div>
                <TbTruckDelivery />
              </div>
            </IconContext.Provider>
            <br /> <h4 className="text-warning">Free pick & drop.</h4>
            <p>Free pick & drop facility at your doorstep.</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <IconContext.Provider value={{ color: "black", size: "4rem" }}>
              <div>
                <BsCheck2Square />
              </div>
            </IconContext.Provider>
            <br /> <h4 className="text-warning">Quality assurance</h4>
            <p>We give best Ironing & dry cleaning experience.</p>
          </Col>
          <Col>
            <IconContext.Provider value={{ color: "black", size: "4rem" }}>
              <div>
                <TiStopwatch />
              </div>
            </IconContext.Provider>
            <br /> <h4 className="text-warning">Convenience </h4>
            <p>Book your service in less than 20 sec</p>
          </Col>{" "}
        </Row>
      </div>
      <br />
      <hr />
      <h2 className="mt-4 text-danger my-4 text-decoration-underline">
        Some of our service showcase.
      </h2>
      <Row>
        <Col></Col>
        <Col>
          {" "}
          <Carousel className="w-100">
            <Carousel.Item>
              <img
                className="d-block "
                src={carouselImg1}
                style={{ height: "400px", width: "400px" }}
                alt="First slide"
              />
              <Carousel.Caption>
                {/* <h3>First slide label</h3>
                <p>
                  Nulla vitae elit libero, a pharetra augue mollis interdum.
                </p> */}
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block "
                style={{ height: "400px", width: "400px" }}
                src={carouselImg2}
                alt="First slide"
              />
              <Carousel.Caption>
                {/* <h3>First slide label</h3>
                <p>
                  Nulla vitae elit libero, a pharetra augue mollis interdum.
                </p> */}
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block "
                style={{ height: "400px", width: "400px" }}
                src={carouselImg3}
                alt="First slide"
              />
              <Carousel.Caption>
                {/* <h3>First slide label</h3>
                <p>
                  Nulla vitae elit libero, a pharetra augue mollis interdum.
                </p> */}
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </Col>
        <Col></Col>
      </Row>

      <br />
      <hr />
      <h3 className="text-danger text-decoration-underline">FAQs</h3>
      <Accordion defaultActiveKey="">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            1.How does it work delivery pickup & drop work ?
          </Accordion.Header>
          <Accordion.Body>
            You can place order & rider will come at your doorstep for free
            pickup and free drop.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            2. How long does it take to process a order?
          </Accordion.Header>
          <Accordion.Body>
            Usually delivery is done in 48-72 hours.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>
            3. Can you deliver on urgent basis?
          </Accordion.Header>
          <Accordion.Body>
            Yes! We do but, Extra charges are applicable.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>4. Do you service INERWEAR?</Accordion.Header>
          <Accordion.Body>No, we do not service inerwear.</Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>5. What are your store timings?</Accordion.Header>
          <Accordion.Body>
            Shridhar nagar store - 9.00 am To 9.00 pm
            <br />
            Kakade township store - 9.00am To 9.00 pm
            <br />
            Lunch break - 2.00pm To 5.00pm
            <br />
            Thursday closed
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <br />
      <hr />
      <Footer />
    </div>
  );
}

export default App;
