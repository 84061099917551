import React, { useReducer, useEffect, useState } from "react";
import { Form, Row, Col, Button, Table, InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";

const initialState = {
  //global for order
  owner_id: 0,
  customer_name: "",
  date_of_delivery: "",
  customer_phone: "",
  // date_of_order: "",
  total_nos: 0,
  //for adding service
  order_id: 0,
  serviceData: [],
  category: "mens",
  garment: "generic",
  service: "",
  nos: 0,
  price: 0,
  subTotal: 0,
  total: 0,
  advance: 0,
  balance: 0,
  //show success
  add: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "ownerid":
      return { ...state, owner_id: action.payload };
    case "neworderid":
      return { ...state, order_id: action.payload };
    case "fetch-load":
      return { ...state, serviceData: action.payload };
    // case "add-details":
    //   return { ...state, details: action.payload };
    case "custname":
      return { ...state, customer_name: action.payload };
    case "custname_phone":
      return { ...state, customer_phone: action.payload };
    case "home_delivery":
      return { ...state, home_delivery: action.payload };
    case "dateOfDel":
      return { ...state, date_of_delivery: action.payload };
    case "customer_id":
      return { ...state, customer_id: action.payload };
    case "category":
      return { ...state, category: action.payload };
    case "garment":
      return { ...state, garment: action.payload };
    case "service-price":
      return {
        ...state,
        service: action.payload.service,
        price: action.payload.price,
      };
    case "comment":
      return {
        ...state,
        comment: action.payload,
      };
    case "nos-subtotal":
      return {
        ...state,
        nos: action.payload.nos,
        subTotal: action.payload.subTotal,
      };
    case "totalntotalnos":
      return {
        ...state,
        total: action.payload.total,
        total_nos: action.payload.ts,
      };
    case "advance":
      return {
        ...state,
        advance: action.payload,
      };
    case "balance":
      return { ...state, balance: state.total - state.advance };
    case "success-add":
      return { ...state, add: true };
    case "reset":
      return {
        ...state,
        garment: "",
        subTotal: 0,
        price: 0,
        nos: 0,
        service: "",
      };
    default:
      return state;
  }
};
const AddNewService = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [details, setDetails] = useState([]);
  const {
    order_id,
    owner_id,
    serviceData,
    customer_name,
    customer_phone,
    category,
    garment,
    service,
    nos,
    price,
    subTotal,
    total,
    total_nos,
    balance,
    advance,
    date_of_delivery,
    add,
    home_delivery,
    comment,
  } = state;
  const backendURI = process.env.REACT_APP_BK_URL;

  useEffect(() => {
    // const ownerName = JSON.parse(localStorage.getItem("username"));
    const ownerid = JSON.parse(localStorage.getItem("id"));
    dispatch({ type: "ownerid", payload: ownerid });
    // if (ownerName === "ganesh") {
    //   dispatch({ type: "ownerid", payload: 1 });
    // } else if (ownerName === "shankar") {
    //   dispatch({ type: "ownerid", payload: 2 });
    // }
  }, []);

  useEffect(() => {
    dispatch({ type: "balance" });
  }, [advance]);

  useEffect(() => {
    let t = 0;
    let tnos = 0;

    details.map((item) => {
      // console.log(item);
      if (typeof item === "object") {
        t = t + item.subTotal;
        tnos = tnos + parseInt(item.nos);
        return dispatch({
          type: "totalntotalnos",
          payload: {
            total: t,
            ts: tnos,
          },
        });
      } else {
        return console.log("some items are not objects");
      }
    });
  }, [details, setDetails]);

  // console.log(details);
  useEffect(() => {
    fetch(`${backendURI}/api/${category}`)
      .then((res) => res.json())
      .then((json) => {
        dispatch({ type: "fetch-load", payload: json });
      });
  }, [category, backendURI]);

  useEffect(() => {
    let newArr2 = details.map((v) => Object.assign(v, { order_id: order_id }));
    newArr2.forEach(postDetails);
  }, [order_id]);

  const postDetails = (item) => {
    fetch(`${backendURI}/api/details`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(item),
    });
  };

  const setPrice = (e) => {
    for (let i = 0; i < serviceData.length; i++) {
      if (serviceData[i].garment === garment) {
        dispatch({
          type: "service-price",
          payload: {
            service: e.target.value,
            price: serviceData[i][e.target.value],
          },
        });
      }
    }
  };
  const setSubTotal = (e) => {
    dispatch({
      type: "nos-subtotal",
      payload: { nos: e.target.value, subTotal: price * e.target.value },
    });
  };

  const onAdd = () => {
    const allDetails = {
      // order_id,
      category,
      garment,
      service,
      nos,
      price,
      subTotal,
      comment,
    };
    // console.log(allDetails);
    setDetails((pre) => [...pre, allDetails]);
  };

  const postOrder = () => {
    fetch(`${backendURI}/api/orders`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify({
        owner_id: owner_id,
        customer_name: customer_name,
        nos: total_nos,
        date_of_delivery: date_of_delivery,
        total: total,
        advance: advance,
        balence: balance,
        home_delivery: home_delivery,
        customer_phone: customer_phone,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        // console.log(json);
        if (json[0].customer_name) {
          dispatch({ type: "neworderid", payload: json[0].id });
          dispatch({ type: "success-add" });
        }
      });
  };

  const custFind = (e) => {
    e.preventDefault();
    setTimeout(() => {
      fetch(`${backendURI}/api/users/findone/${e.target.value}`)
        .then((res) => res.json())
        .then((json) => {
          try {
            dispatch({ type: "custname", payload: json[0].name });
            dispatch({ type: "custname_phone", payload: json[0].phone });
          } catch {
            dispatch({
              type: "custname",
              payload: "No customer with this phone found.",
            });
          }
        });
    }, 1100);
  };

  const handleHd = (e) => {
    if (e.target.checked === true) {
      dispatch({ type: "home_delivery", payload: true });
    } else {
      dispatch({ type: "home_delivery", payload: null });
    }
  };

  const handleDelete = (index) => {
    let newarr1 = details.toSpliced(index, 1);
    setDetails(newarr1);
  };
  return (
    <div>
      {add === false ? (
        <>
          {" "}
          <Form>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Customer Phone/Name</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={(e) => {
                      custFind(e);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col>
                <h4 className="mx-4">
                  Customer Name: <br />{" "}
                  <p className="text-success"> {customer_name} </p>
                </h4>
              </Col>
              <Col>
                <Form.Check
                  value="home_delivery"
                  onChange={(e) => {
                    handleHd(e);
                  }}
                  label="Home Delivery"
                />
              </Col>
            </Row>
          </Form>
          <br />
          {/* <Col>
              <h4>Date of Delivery: {date_of_delivery}</h4>
            </Col> */}
          <hr />
          <br />
          <h3 className="text-center">Add Service</h3>
          <br />
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Category</Form.Label>
                <Form.Select
                  onClick={(e) =>
                    dispatch({ type: "category", payload: e.target.value })
                  }
                >
                  <option value="mens">Choose a Category</option>
                  <option value="mens">Mens</option>
                  <option value="women">Women</option>
                  <option value="kids">Kids</option>
                  <option value="family">Family</option>
                  <option value="home_decor">Home Decor</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              {state.serviceData.length ? (
                <Form.Group className="mb-3">
                  <Form.Label> Garment</Form.Label>
                  <Form.Select
                    onClick={(e) => {
                      dispatch({ type: "garment", payload: e.target.value });
                    }}
                  >
                    {category !== "family" ? (
                      <>
                        <option>Choose Garment</option>
                        {state.serviceData.map((item, i) => (
                          <option key={i} value={item.garment}>
                            {item.garment}
                          </option>
                        ))}
                      </>
                    ) : (
                      <option key={1} value={"generic"}>
                        {" "}
                        Generic
                      </option>
                    )}
                  </Form.Select>
                </Form.Group>
              ) : (
                <Form.Group className="mb-3">
                  <Form.Label>Garment</Form.Label>
                  <Form.Select>
                    <option>Choose A Garment</option>
                  </Form.Select>
                </Form.Group>
              )}
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Service</Form.Label>
                {category !== "family" ? (
                  <Form.Select
                    onClick={(e) => {
                      setPrice(e);
                    }}
                  >
                    <option value="#">Choose a Service</option>
                    <option value="press">press</option>
                    <option value="steam">steam</option>
                    <option value="dryclean">dryclean</option>
                    <option value="petrol_dryclean">Petrol Dryclean</option>
                    <option value="alteration">Alteration</option>
                    <option value="raffu">Raffu</option>
                    <option value="dyeing">Dyeing</option>
                    <option value="embroidary">Embroidary</option>
                  </Form.Select>
                ) : (
                  <Form.Select
                    onClick={(e) => {
                      setPrice(e);
                    }}
                  >
                    <option value="#">Choose a Service</option>
                    <option value="press">press</option>
                    <option value="p_press">primium-press</option>

                    <option value="steam">steam</option>
                  </Form.Select>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Nos</Form.Label>
                <Form.Control
                  onChange={(e) => {
                    setSubTotal(e);
                  }}
                  type="number"
                  min="0"
                  placeholder=""
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Rate</Form.Label>
                <Form.Control
                  readOnly
                  type="disabled"
                  placeholder={`Rs:${price}/piece`}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Subtotal</Form.Label>
                <Form.Control readOnly type="disabled" placeholder={subTotal} />
              </Form.Group>
            </Col>
            <Col>
              <Button
                className="mt-4"
                onClick={onAdd}
                variant="outline-success"
                size="lg"
              >
                Add
              </Button>
            </Col>
          </Row>
          <Row>
            <InputGroup>
              <InputGroup.Text>Comment</InputGroup.Text>
              <Form.Control
                onChange={(e) => {
                  dispatch({ type: "comment", payload: e.target.value });
                }}
                as="textarea"
                aria-label="Comment"
                size="xs"
              />
            </InputGroup>
          </Row>
          <br />
          <h3 className="text-center ">Order Details</h3>
          <br />
          <Table striped bordered hover>
            <thead>
              <tr>
                <th> </th>
                <th>Category</th>
                <th>Garment</th>
                <th>Service</th>
                <th>Price</th>
                <th>Nos</th>
                <th>SubTotal</th>
                <th>Comment</th>
                {/* <th></th> */}
              </tr>
            </thead>
            <tbody>
              {details.map((item, i) => {
                return (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{item.category}</td>
                    <td>{item.garment}</td>
                    <td>{item.service}</td>
                    <td>{item.price}</td>
                    <td>{item.nos}</td>
                    <td>{item.subTotal}</td>
                    <td>{item.comment}</td>
                    <td>
                      <Button variant="danger" onClick={() => handleDelete(i)}>
                        Del
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <br />
          <hr />
          <Row>
            <Col>
              <h4 className="text-center">Total No Of Garments</h4>
            </Col>
            <Col>
              {" "}
              <h4 className="text-center"> {total_nos} </h4>{" "}
            </Col>
          </Row>
          <Row>
            <Col>
              <h4 className="text-center">Total</h4>
            </Col>
            <Col>
              <h4 className="text-center"> ₹{total}/- </h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <h4 className="text-center">Advance</h4>
            </Col>

            <Col>
              <Form.Control
                className="text-center fs-4 fw-bold"
                type="number"
                onChange={(e) => {
                  dispatch({ type: "advance", payload: e.target.value });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <h4 className="text-center"> Balance </h4>
            </Col>
            <Col>
              <h4 className="text-center"> ₹{balance}/- </h4>
            </Col>
          </Row>
          <br />
          <Row>
            <Button size="lg" variant="success" onClick={postOrder}>
              Add Order
            </Button>
          </Row>{" "}
        </>
      ) : (
        <>
          <h3 className="text-success"> The order has been added.</h3>
          <Link to="/admindash">&#8592; Go to admin dashboard.</Link>
        </>
      )}
    </div>
  );
};

export default AddNewService;
